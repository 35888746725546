import React, {useState, useEffect, useRef, Fragment} from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Button, Divider, Grid, Typography, Tooltip} from '@material-ui/core'
import ModalMonitorIndividual from './ModalMonitoramentoIndividual'
import Swal from 'sweetalert2'
import LockIcon from '@material-ui/icons/Lock'
import Close from '@material-ui/icons/Close'
import DetailIcon from '@material-ui/icons/Apps'
import { Alert, AlertTitle } from '@material-ui/lab'


export default function SideBarGestaoColetas(props){

    const [open, setOpen] = useState(false)

    const toggleDrawer = () =>{
        setOpen(!open)
    }

    return <div>
    <React.Fragment >
       
        <Drawer
             
            disableEscapeKeyDown
          anchor={'right'}
          open={props.showModal}
          hideBackdrop
          onClose={props.closeModal}
        >
            <div style ={{width: "100%", height : '55px', backgroundColor:"#37474F"}}></div>
            <div style ={{width: "100%", height : '28px', backgroundColor:"#CFD8DC", textAlign:'center'}}>
                <Typography variant='caption' style={{textAlign : 'center'}}>PROPRIEDADE DA COLETA</Typography>
            </div>
            {props.coleta.dias > 0 ? <Alert color='error'>
                    <AlertTitle>Sem Comunicação</AlertTitle>
                    <Typography variant='caption'>Sem resposta há {props.coleta.dias} dia(s)</Typography>
                </Alert> : ''}
          <div id='div-side-menu' style ={{
            width : '400px', 
            padding : '30px', 
            paddingTop : props.coleta.dias > 0 ? '10px' : '30px'
            
          }}>
            
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <Typography variant='subtitle2'>Modelo</Typography>
                    <Typography variant='subtitle1'>{props.coleta.modelo}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <Typography variant='subtitle2'>Marca</Typography>
                    <Typography variant='subtitle1'>{props.coleta.marca}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <Typography variant='subtitle2'>Nº de Série</Typography>
                    <Typography variant='subtitle1'>{props.coleta.numeroserie}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <Typography variant='subtitle2'>Cliente</Typography>
                    <Typography variant='subtitle1'>{props.coleta.razaosocial}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <Typography variant='subtitle2'>Data 1º Coleta</Typography>
                    <Typography variant='subtitle1'>{props.coleta.horacadastro ? props.coleta.datacadastro+' '+props.coleta.horacadastro.substr(0,8) : ''}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <Typography variant='subtitle2'>Data Última Coleta</Typography>
                    <Typography variant='subtitle1'>{props.coleta.horacoleta ? props.coleta.datacadastro+' '+props.coleta.horacoleta.substr(0,8) : ''}</Typography>
                </Grid>
            </Grid>
                
            <Divider style={{marginTop : '10px'}}/>
            <Grid container spacing = {1} style={{marginTop : '10px'}}>
                <Grid item xs = {12}>
                   <Button fullWidth variant='contained' style={{height :'80px', display:"flex"}} onClick={()=>{
                    //props.closeModal();
                    props.setColeta(props.coleta);
                   }}>
                     <div style ={{width : '20%'}}><DetailIcon style={{fontSize : '62px', color:"#444"}}/></div>
                    <div style ={{width : '80%'}}>Exibir Detalhes</div>
                   </Button>
                </Grid>
                
            </Grid>
            {!props.dispositivosOFF ? <Grid container spacing = {1} style={{marginTop : '2px'}}>
                <Grid item xs = {12}>
                   <ModalMonitorIndividual fullWidth variant='contained' 
                   disabled={!props.coleta.idcliente}
                   large = {true}
                   coleta={props.coleta}/>
                </Grid>
            </Grid> : ''}




            
            <Grid container spacing = {1} style={{marginTop : '2px'}}>
                <Grid item xs ={12}>
                <Tooltip title ='Click aqui para remover este equipamento definitivamente'>
                                    <Button 
                                        size='large'
                                        style={{height :'80px', display : 'flex'}}
                                        variant = 'contained' 
                                        disabled={props.coleta && !props.coleta.idcliente && !props.coleta.numeroserie} 
                                        fullWidth  
                                        onClick={()=>{
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text : 'Deseja DESABILITAR o monitoramento deste equipamento?', 
                                                icon : 'question', 
                                                showConfirmButton : true, 
                                                confirmButtonText : 'Sim', 
                                                showCancelButton : true, 
                                                cancelButtonText : 'Não'
                                            }).then(resposta=>{
                                                if(resposta.isConfirmed){
                                                    props.Conexao.post('coletasController/desativarColeta?idempresa',{
                                                        idEmpresa : props.coleta.idempresa, 
                                                        idCliente : props.coleta.idcliente, 
                                                        idColeta : props.coleta.idcoleta
                                                    }).then(response=>{
                                                        if(response.data.status =='OK'){
                                                            Swal.fire({
                                                                title : 'Datacount', 
                                                                text:'Operação realizada com sucesso !', 
                                                                showCancelButton : false, 
                                                                showConfirmButton : false, 
                                                                timer  : 1500, 
                                                                icon : 'success'
                                                            })
                                                        props.closeModal();
                                                        props.getData();

                                                        }else{
                                                            Swal.fire({
                                                                title : 'Datacount', 
                                                                text:'Operação não realizada', 
                                                                showCancelButton : false, 
                                                                showConfirmButton : false, 
                                                                timer  : 1500, 
                                                                icon : 'warning'
                                                            })
                                                        }
                                                    }).catch(error=>{
                                                        Swal.fire({
                                                            title : 'Datacount', 
                                                            text:'Ocorreu um erro inesperado !', 
                                                            showCancelButton : false, 
                                                            showConfirmButton : false, 
                                                            timer  : 1500, 
                                                            icon : 'error'
                                                        })
                                                    })
                                                }
                                            })
                                            
                                        
                                        }}>
                                         <div style ={{width : '20%'}}><LockIcon style={{fontSize : '62px', color:"#444"}}/></div>
                                         <div style ={{width : '80%'}}>Desativar Coleta</div>
                                    </Button>
                                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style={{marginTop : '2px'}}>
                <Grid item xs = {12}>
                   <Button fullWidth variant='contained' color='secondary'  style={{height :'80px', display :'flex'}} onClick={()=>{
                    props.closeModal();
                   }}>
                    <div style ={{width : '20%'}}><Close style={{fontSize : '62px', color:"#444"}}/></div>
                    <div style ={{width : '80%'}}>Fechar</div>
                   </Button>
                </Grid>
            </Grid>
          </div>
        </Drawer>
      </React.Fragment>
  </div>
}
