import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Typography} from '@material-ui/core'
import ImagemInterface2 from './Imagens/Interface2.png'




export default function(props){
    return <React.Fragment><div id='sobre-container'>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Typography variant = 'h1'>Sobre o Printerconnect</Typography>
        <br/>
        <br/>
        <div id='sobre-container-text'>
        <p>Sua empresa ainda realiza manualmente a coleta dos contadores de impressão? Esta não é a forma mais eficiênte! Veja como a realidade de <strong>Provedores de Impressão</strong> pode ser transformada.<br/>
        Printerconnect é uma <strong>Solução Modular</strong>, focada em empresas de <strong>Outsourcing de Impressão</strong>, entregando agilidade, confiabilidade e segurança para tornar o dia a dia das empresas mais eficiente :</p>
        <br/>
    
    
        
  
       
        </div>
    </div>
    <div id='div-container-pmc'>
        <div id ='div-imagem'>
            <img src ={ImagemInterface2} />
        </div>
       <div id='div-texto'>
           <Typography variant='h5'>Printerconnect Admin</Typography>
           <br/>
           <ul>
      		<strong>Funcionalidades:</strong>
      		
      		<li>- Coleta automática de contadores</li>
      		<li>- Pedido automático  de suprimentos</li>
      		<li>- Aberturas automáticas de Ordens de Serviço</li>
            <li>- Atualização de contadores ERP Dataclassic</li>
            
      	</ul>
       </div>
    </div>
    </React.Fragment>
}