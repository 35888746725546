import React, {Fragment, useState, useEffect} from 'react'
import {withRouter, Link} from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles';
import Navbar from './Components/Navbar'
import LinearProgress from '@material-ui/core/LinearProgress';
import BarraPrincipal from './BarraPrincipal'
import {Accordion, AccordionDetails, AccordionSummary, Typography, Tooltip,Button} from '@material-ui/core'
import  ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DashIcon from '@material-ui/icons/Timeline'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import VPNIcon from '@material-ui/icons/VpnKey';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import UsbIcon from '@material-ui/icons/Usb';
import IconeEquipamentos from '@material-ui/icons/Print'
import SelecionaEmpresaAtiva from '../../components/SelecionaEmpresaAtiva'
import ReportIcon from '@material-ui/icons/Assignment'
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import ClipIcon from '@material-ui/icons/AttachFile'

import GetAppIcon from '@material-ui/icons/GetApp';

import HelpIcon from '@material-ui/icons/LiveHelp'
import LogoDatabit from '../../components/imagens/logo-databit.png'
import ScrollShadow from 'react-scroll-shadow';
import logoDatacount from '../LandingPage/Componentes/logo-databit.png'
import './Principal.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/HighlightOff'
import {logout  } from '../../providers/Autenticate';
import Swal from 'sweetalert2';

let timer = null;
var varUsuario =null;
var varEmpresa = null;

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
      padding : 0
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      fontWeight:'normal!important',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      paddingLeft:'0px',
      paddingRight:"10px", 
      paddingLeft : '70px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));
const Principal = function(props){
    const [expanded, setExpanded] = React.useState('');
    const [empresa, setEmpresa] = React.useState([]);
    const [empresaAtiva, setEmpresaAtiva] = React.useState([]); 
    const classes = useStyles();
    React.useEffect(()=>{
        const emp = sessionStorage.getItem('empresa@printerConnect', []);
        const empAtiva = sessionStorage.getItem('empresaAtiva@printerConnect', null);
        setEmpresa(JSON.parse(emp))
        if(empAtiva){
          setEmpresaAtiva(JSON.parse(empAtiva))
      }else{
          setEmpresaAtiva({idempresa:-1, razaosocial:''})
          
      }
        
      }, [props.location])
    
    const handleChange = (value) =>{
        if(value !==expanded){
            setExpanded(value)
        }else{
            setExpanded('')
        }
        
    }

    React.useEffect(()=>{
        const emp = sessionStorage.getItem('empresa@printerConnect', []);
        const empAtiva = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect', null));
       
        varEmpresa = empAtiva ? empAtiva.idempresa : -1;
        varUsuario = empAtiva ? empAtiva.idusuario : -1;
        setEmpresa(JSON.parse(emp))
        if(empAtiva){
          setEmpresaAtiva(empAtiva)
      }else{
          setEmpresaAtiva({idempresa:'', razaosocial:''})
          
      }
        
      }, [])

      const setarEmpresaAtiva = (emp) =>{
        setEmpresaAtiva(emp)
      }



      const handleLogoff = () =>{
        logout();
   
        window.location.href ='/'
      }

    return <div style ={{display: 'flex', height:'99vh'}}>
          <div id = 'nav-datacount-side'>
            <div id='nav-datacount-side-title'>
           <SelecionaEmpresaAtiva idUsuario={empresa.idusuario} empresaAtiva={empresaAtiva.idempresa} setEmpresaAtiva = {(emp)=>{
            setarEmpresaAtiva(emp)
          }}/>
            </div>
            <div id='nav-datacount-side-logo'>
                <img src={logoDatacount} height={'60px'}/>
            </div>
            <Accordion elevation={0} expanded={expanded === 'panel1'} onChange={()=>handleChange('panel1')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <NetworkCheckIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Dashboard</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>
                               
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/grafico-clientes' : '', state :{
                                            empresa : empresaAtiva, 
                                            }}}> 
                                    Visão Coletas
                                </Link>                              
                               
                            </div>
                            
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={()=>handleChange('panel2')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <AccountTreeIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Empresas</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>                                                              
                                <Link className='div-hover' to ={{pathname : '/minheempresa', state :{
                                        empresa : empresaAtiva, 
                                        }}}>
                                    Meus Dados
                                </Link>
                                

                                <Link className='div-hover' to ={{pathname : '/cadastro-empresa', state :{
                                            empresa : empresa, 
                                            }}}>
                                       Cadastro de Empresas
                                </Link>  
                                
                                <Link className='div-hover' to ={{pathname : '/mapa-empresa/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa, state:{
                                    empresa : empresa, 
                                    usuario : empresa.idusuario
                                    }}}>
                                    Visão Parque (mapa)
                                </Link> 
                            
                                <Link className='div-hover' to ={{pathname : '/cadastro-regioes/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa}}>
                                    Cadastro de Regiões
                                </Link>  
                               
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={()=>handleChange('panel3')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <GroupAddIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Clientes</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>

                                <Link className='div-hover' to ={{
                                    pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/cadastro-clientes':'', state:{
                                    empresa : empresaAtiva, 
                                    }}}>
                                    Clientes
                                </Link>
                         

                               
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={()=>handleChange('panel4')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <UsbIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Collector</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>
                               
                                {
                                    (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 &&empresaAtiva.idempresa == 3) &&
                                    
                                    <Link className='div-hover'  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa == 3) ? '/homologacoes/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Homologar
                                    
                                    </Link>
                                }
                                {
                                        (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 &&empresaAtiva.idempresa == 3) &&
                                    <Link className='div-hover'  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa == 3) ? '/parametros_dca/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Templates
                                    </Link>
                                }
                                


                       
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/licencas_collect/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Licenças Coleta
                                </Link>
                           
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/dca/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Chaves DCA
                                </Link>
                
                     

                                  
      
                                
                          
                        </div>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion expanded={expanded === 'panel5'} onChange={()=>handleChange('panel5')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <IconeEquipamentos style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Equipamentos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>
                               
                               


                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/novosdispositivos/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                   Descobertas (novos)
                                </Link>

                               {/* <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Contadores Ao Vivo
                            </Link> */}  
                  
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                   Visão de Parque
                                </Link> 

                                  
                                                                          
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_desabilitados/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Equipamentos Desabilitados
                                </Link>    


                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas-atolamentos' : '/404', state :{
                                empresa : empresaAtiva
                                }}}>
                                    Alertas de Atolamentos
                                </Link> 
    
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas_suprimentos/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Alertas Toner
                                </Link> 

                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas_consumiveis/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Parts
                                </Link>

                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/AlertasManutencao/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Chamados de Serviço
                                </Link>   

         
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/trocas_suprimento/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
                                    Trocas Toner
                                </Link> 
                               
                          
                        </div>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion expanded={expanded === 'panel6'} onChange={()=>handleChange('panel6')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <ReportIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Relatórios</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>
                               
                               
                            <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/relatorios/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa : '/404'}}>
                                   Relatórios
                            </Link>  

                               
                          
                        </div>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion rounded={false} expanded={expanded === 'panel7'} onChange={()=>handleChange('panel7')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <GetAppIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Downloads</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>
                               <a className='div-hover' href ="https://apidatacountserv1.websiteseguro.com/api/downloads/databitCollector-setup.zip" target='_blank'>
                                    Databit DCA
                                </a>
                                <a className='div-hover' href ="https://apidatacountserv1.websiteseguro.com/api/downloads/datacountServices.rar" target='_blank'>
                                    Databit API Connect
                                </a>
                            </div>
                            
                        </AccordionDetails>
                    </Accordion>


                    {
                    (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 &&empresaAtiva.idempresa == 3) &&
                    <Accordion expanded={expanded === 'panel8'} onChange={()=>handleChange('panel8')}>
                        <AccordionSummary
                        className='title-menu-hover'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                        <div style={{ width: '25%', flexShrink: 0 }}>
                            <VPNIcon style={{ color: '#666' }}/>
                        </div>
                        <Typography style={{ color: '#666' }}>Gestão Colletor</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <div style={{display:'inline-grid', paddingLeft : '20px', width : '100%'}}>
                       
                                <Link className='div-hover' to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/revendas' : '/404'}}>
                                Gestão de Revendas
                                </Link> 
                               
                            </div>
                            
                        </AccordionDetails>
                    </Accordion>}
          </div>
          <div id = 'container-datacount-main'>
          <div id = 'nav-datacount-main' style ={{display:'flex'}}>
            <div style= {{width : '90%', paddingLeft:'10px'}}>
                <Tooltip title='Empresa ativa (Em uso)'>
                    <Typography className={classes.title} variant='subtitle1'  noWrap style={{ cursor:'help', color:'#fff'}}>
                        Empresa : {empresaAtiva.idempresa+' '+empresaAtiva.razaosocial+ ' '} 
                    </Typography>
                </Tooltip>

                <Tooltip title='Revenda licenciada'>
                <Typography className={classes.title} style={{fontSize:"10px", cursor:'help', color:'#fff'}}  noWrap>
                        Revenda : {empresa.nome} 
                    </Typography>
              </Tooltip>
              </div>
              <div style= {{width : '10%', textAlign:'center', paddingTop:'10px'}}>
                <Button onClick={()=>{
                    Swal.fire({
                        title  : 'Datacount',
                        text :' Deseja realmente sair?', 
                        icon:'question', 
                        showCancelButton :true, 
                        cancelButtonText : 'Não', 
                        showConfirmButton : true, 
                        confirmButtonText :'Sim'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            handleLogoff()
                        }
                    })
                    
                }}>
                    <CloseIcon  color='secondary'/>
                    <Typography variant='caption' color='secondary'> Sair</Typography>
                </Button>
                
              </div>
          </div>
            {props.atualizar && <LinearProgress color='secondary'/>}
            <div id='container-full'>
            {
                    props.children 
                }
            </div>
          </div>
        
          </div >
}

export default withRouter(Principal)