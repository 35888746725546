import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge, Checkbox} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import FormNovoCliente from './FormNovoCliente'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonAtivoInativo from '../../components/Comuns/ButtonAtivoInativo'
import ClienteAtivo from '@material-ui/icons/Domain';
import ClienteInativo from '@material-ui/icons/DomainDisabled';
import SearchIcon from '@material-ui/icons/Search';

import TablePagination from '@material-ui/core/TablePagination';

import DisplayStatus from '../../components/DisplayStatus'
import ModalGestaoAcessoCliente from './Componentes/ModalGestaoAcessoCliente'
import TextFieldEmail from '../../components/TextFieldEmail'
import IconeNovoLocal from '@material-ui/icons/AddLocation'
import Iconfilter from '@material-ui/icons/FilterList'
import DesativarIcon from '@material-ui/icons/Block'
import AtivarIcon from '@material-ui/icons/Done'
import BuildIcon from '@material-ui/icons/Build'
import RemoveIcon from '@material-ui/icons/DeleteForever'
import DetalhesIcon from '@material-ui/icons/Assignment'
import ModalDetalhesCliente from './ModalDetalhesCliente';


const CadastroClientes = (props)=>{
    const controller ='clientesController';
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [clientes, setClientes] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [selecionado, setSelecionado] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState('ativos')
    const [filtro, setFiltro] = useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [paginas, setPaginas]= React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);





    const refStatusCadastro = useRef();

    const refCpnj = useRef();
    const refRazaoSocial = useRef();
    const refPlaca = useRef();
    const refCidade = useRef();
    const refMarca = useRef();
    const refModelo = useRef();
    const refSetor = useRef();
    const refErp = useRef();
    const refStatus = useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(()=>{
        getClientes();
    }, [page])
    useEffect(()=>{
        getClientes();
    }, [rowsPerPage])


    useEffect(()=>{
       console.log(props)
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getClientes = () =>{
        setSelecionado([])
        setEdicao([])
        setLoading(true)
        Conexao.post(controller+'/getClientes', {
            idUsuario : props.location.state.empresa.idrevenda, 
            idEmpresa : props.location.state.empresa.idempresa, 
            filtro : [], 
            tipoFiltro : 'ativos', 
            status : refStatus.current.checked ? 'TODOS' : 'ATIVO',
            filtroStatus : '',            
            cnpj : refCpnj.current ? refCpnj.current.value : '', 
            razaosocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
            cidade : refCidade.current ? refCidade.current.value : '', 
            codigoClienteERP : refErp.current ? refErp.current.value : '', 
            limit: rowsPerPage,
            offSet :  page
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                   setClientes(response.data.payload.registros);
                   setPaginas(response.data.payload.totalRegistros)
                   
                }else{
                   setClientes([])
                }
            }else{
            setClientes([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
            setClientes([])
        })
    }

   /* const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.history.push('/cadastro_clientes/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/page/'+newPage);
        getClientes(newPage)
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };*/


    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de Clientes
            </Link>                
        </Breadcrumbs>
    }>
        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px',}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>
            
                        <Grid item xs = {7}></Grid>
                        <Grid item xs = {5}>
                        <TablePagination
                            component="div"
                            count={paginas}
                            page={page}                
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage='Linhas por página'
                            />
                        </Grid>
                    </Grid>
        </div>


   
    <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'30px'}}>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><span>Nome</span></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                        getClientes()
                    }
                }}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>getClientes()}><SearchIcon/></Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                disabled={selecionado && selecionado.idcliente} 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                onClick={()=>setOpenModal(true)}>
                    <AddIcon /> Novo
            </Button>
        </Grid>
        {/*<Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <ModalDetalhesCliente 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'}
                cliente ={selecionado}
                
                />
            </Grid>*/}
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined' fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'} onClick={()=>{
                setEdicao(selecionado)
                setOpenModal(true)
                
            }}><BuildIcon /> Editar</Button>
        </Grid>
        
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                fullWidth 
                style={{height:'100%', 
                backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja DESATIVAR este cliente?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.get('clientesController/disableRegistro?idempresa='+selecionado.idempresa+'&idcliente='+selecionado.idcliente).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    getClientes()
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <DesativarIcon /> Desativar
            </Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 't'} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja REATIVAR este cliente?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.get('clientesController/enableRegistro?idempresa='+selecionado.idempresa+'&idcliente='+selecionado.idcliente).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    getClientes()
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Reativar
            </Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined' fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} disabled={selecionado && !selecionado.idcliente} onClick={()=>{
                 Swal.fire({
                    title : 'Datacount', 
                    text : 'Deseja EXCLUIR este cliente? esta ação não poderá ser desfeita!', 
                    icon : 'error', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                }).then(resposta=>{
                    if(!resposta.isConfirmed){
                        return;
                    }
                })
                
            }}><RemoveIcon /> Excluir</Button>
        </Grid>
        <Grid item spacing={1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
         <ModalGestaoAcessoCliente 
            usuario={props.location.state.empresa.idrevenda}
            empresa={selecionado.idempresa}
            idusuario={selecionado.idusuario}
            idempresa = {selecionado.idempresa}
            idcliente ={selecionado.idcliente}
            cliente={selecionado.idcliente}            
            disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'}                                              
            razaosocial={selecionado.razaosocial}/>
        </Grid>
    </Grid>
    <Grid container spacing={1} style={{marginTop :'5px'}}>
        <Grid item xs = {6}>            
            <Checkbox   inputRef={refStatus}/><Typography  variant='caption'>Exibir clientes desativados?</Typography>
        </Grid>
    </Grid>
   <div className='div-form-container' style={{paddingTop : '0px!important'}}>
    <Grid container style = {{marginTop:"0px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-small table-sm'>
            <TableHead>
                <TableCell align='center' style = {{width:"10%"}}>Status</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>CNPJ</TableCell>
                <TableCell style = {{width:"55%"}}>Nome</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Cliente ERP</TableCell>                
                <TableCell align='center' style = {{width:"15%"}}>Cidade</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Estado</TableCell>

            </TableHead>

            <TableBody>
                {
                    clientes && clientes.map(function(cliente, i){
                        return <TableRow key={'lGLC'+i} style ={{
                            backgroundColor : selecionado && selecionado.idcliente == cliente.idcliente ? "#E3F2FD" : '', textDecoration: cliente.ativo =='f' ? 'line-through!important' : '', 
                            opacity : selecionado.idcliente && selecionado.idcliente != cliente.idcliente ? 0.5 : 1 
                        }}   onClick={()=>{
                            if(selecionado && selecionado.idcliente == cliente.idcliente){
                                setSelecionado([])
                            }else{
                                setSelecionado(cliente)
                            }
                            
                            
                        }}>
                               <TableCell align='center'>
                                {
                                    cliente.ativo =='t' ? <Tooltip title='Cliente ativo'><ClienteAtivo style ={{color:'#444'}}/></Tooltip> : <Tooltip title='Cliente inativo'><ClienteInativo style ={{color:'#ccc'}}/></Tooltip>
                                }
                                </TableCell>
                                <TableCell align='center'><Typography variant='caption' >{cliente.cpf_cnpj}</Typography></TableCell>
                                <TableCell>
                                    <Typography variant='subtitle2' >{cliente.razaosocial}</Typography>                                                                     
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography variant='subtitle2' >{cliente.idclienteinterno}</Typography>                                                                     
                                </TableCell>         
                                                      
                                <TableCell align='center'><Typography variant='caption' >{cliente.cidade}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' >{cliente.estado}</Typography></TableCell>                               
                               
                               
                            </TableRow> 
                    }) 
                }
                {clientes.length == 0 && <TableRow>
                    <TableCell colSpan={11}>
                    <Typography variant = 'caption' color='primary'>* Não existe(m) registro(s)</Typography>
                    </TableCell>
                    
                    
                    </TableRow>}
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <ModalFull 
        showModal={openModal} 
        fixedSize={true}
        size='md'
        closeModal = {()=>{setOpenModal(false)}}
        titulo= {edicao && edicao.idcliente ? 'Alteração cliente : '+edicao.razaosocial+'('+edicao.idcliente+')' : 'Cadastrar Novo Cliente'} >
        <FormNovoCliente  
            idempresa={props.location.state.empresa.idrevenda}
            chaveEmpresa={props.location.state.empresa.idempresa}
            edicaoClientes = {edicao}
            updateClientes = {()=>getClientes()}
            closeModal = {()=>{setOpenModal(false)}}/>
    </ModalFull>
   

    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroClientes)