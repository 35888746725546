import React, {Fragment, useEffect, useState, useRef} from 'react'
import {withRouter} from 'react-router-dom'
import {Breadcrumbs} from '@material-ui/core'
import {Grid, TextField, Tooltip, Divider, Button, Typography, InputAdornment} from '@material-ui/core'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import IconeExcel from './imagens/iconeExcel.svg'
import IconeHtml from './imagens/iconeHtml.svg'
import {Link } from 'react-router-dom'
import Template from '../Template/Template';

const WebClienteRelatoriosCollector = (props) =>{
    const [cliente, setCliente] = useState(props.location.state.cliente)
    const [meses, setMeses] = useState(1)
    const refReferencia = useRef();



    return <Template breadcrumbs ={
      <Breadcrumbs aria-label="breadcrumb">  
          <Typography>Você esta em:</Typography>          
              <Link color="inherit" to={'/webclient/home'} >
                  Home
              </Link>   
              <Typography>COLLECTOR - Datacount Collector</Typography>       
          </Breadcrumbs>
  }>
      <div style={{paddingLeft : '40px', paddingRight : '40px', paddingTop : '60px'}}>
      <div style={{backgroundColor:"#fff",paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', borderLeft: 'solid 4px #37474F'}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>
            
                <Grid item xs = {12}><Typography variant='h5'>Relatórios Datacount Collector</Typography></Grid>
                
            </Grid>
        </div>
           
            <br/>
           
            <br/>
            {cliente.idcliente &&
            <Grid container spacing={6}>
                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Ao Vivo</Typography>
              
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a  target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresAoVivo?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses=1'}>
                          <Tooltip title='Gerar relatório de contadores Ao Vivo em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresAoVivo?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses=1'}>
                          <Tooltip title='Gerar relatório de contadores Ao Vivo em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>* Relatorio de coleta de contadores extraídos na data de hoje (ao vivo), não incluem equipamentos em datas anteriores.</p>
                  </div>  

                </Grid>
                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Diários</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresDiarios?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento (detalhamento diário) em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresDiarios?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento (detalhamento diário) em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Fechamento Mensal</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.printerconnect.com.br/api/index.php/clientesController/relatorioContadoresMensais?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresMensais?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Modelo</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.printerconnect.com.br/api/index.php/coletasController/relatorioContadoresModelo?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores agrupados por modelo em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.printerconnect.com.br/api/index.php/coletasController/relatorioContadoresModelo?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores agrupados por modelo em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Relatório Parque</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.printerconnect.com.br/api/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://www.printerconnect.com.br/api/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+cliente.idempresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Todos equipamentos listados independente da última data de coleta.
                      </p>
                  </div>  
                </Grid>

             
            </Grid>
  }
  </div>
    </Template>
}

export default withRouter(WebClienteRelatoriosCollector)