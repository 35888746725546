import { getToken } from "./Autenticate";

var axios = require('axios');
var porta = 443;
//var server = 'fleetconnect1.websiteseguro.com'
var server = 'datacount.com.br'
//var server = 'printerconnect.com.br'
var server2 = '127.0.0.1'
var protocolo ='https'
var md5 = require('md5');


const { protocol, host, port } = window.location;
const baseURL = 'https://printerconect.com.br/api/';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {    
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*', 
    'Access-Control-Allow-Headers':'*',      
    'Access-Control-Allow-Methods' : '*'
  }
});
 


  axiosInstance.interceptors.request.use(async config => {
  const token = md5('printerconnect#2021');//getToken();

  if (token) {
    config.headers.Authorization = 'Bearer '+token;
  }

  return config;

});
export default axiosInstance;
