import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Block';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalDcas from './ModalDcas'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalNovaLicenca from './ModalNovaLicenca'
import ModalHabilitaMonitorToner from './Components/ModalHabilitarMonitoramentoToner'
import ModalHabilitarMonitoramentoEventos from './Components/ModalHabilitarMonitoramentoEventos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PrintIcon from '@material-ui/icons/Print';
import {Badge} from '@material-ui/core'
import AtivarIcon from '@material-ui/icons/Done'
import Iconfilter from '@material-ui/icons/FilterList'


const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [licencasCollector, setLicencasCollector] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [selecionado, setSelecionado] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);
    const [ registros, setRegistros] = useState(0)
    const refDataCadastro = React.useRef();
    const refChaveLicenca = React.useRef();
    const refRazaoSocial = React.useRef();
    

    useEffect(()=>{
        getLicencasCollector();
       
    }, [page])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])

    


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getLicencasCollector = () =>{
        
        setLoading(true)
        Conexao.post('dcaController/getLicencasCollector?idempresa='+props.match.params.chaveEmpresa, {
            idUsuario : props.match.params.idempresa, 
            idEmpresa : props.match.params.chaveEmpresa,             
          
            dataCadastro : refDataCadastro.current ? refDataCadastro.current.value : '', 
            chaveLicenca : refChaveLicenca.current ? refChaveLicenca.current.value : '', 
            razaoSocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
            limit: rowsPerPage,
            offSet :  page
        }).then(response =>{
            
            if(response.data.status == 'OK'){
                setLicencasCollector(response.data.payload.dados);
                setRegistros(response.data.payload.registros);
           
               
            }else{
                setLicencasCollector([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

   

    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de Licenças de Coleta
            </Link>                
        </Breadcrumbs>
    }>

        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px'}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>
            
                        <Grid item xs = {7}></Grid>
                        <Grid item xs = {5}>
                        <TablePagination
                                component="div"
                                count={registros}
                                page={page}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage='Linhas por página'
                                />
                        </Grid>
                    </Grid>
        </div>
      
    
        <Grid container spacing ={1} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'30px'}}>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><SearchIcon/></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                    
                    }
                }}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} ><Iconfilter/></Button>
        </Grid>
        
        
        <Grid item xs ={ 2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title ='Click aqui para habilitar o monitoramento deste equipamento'>
            <Button 
                variant = 'outlined' 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={!selecionado.idcliente || (selecionado && (selecionado.idcliente && selecionado.ativo =='t'))}  onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja HABILITAR esta Licença de Coleta?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            setSelecionado([])
                            Conexao.post('dcaController/ativarLicencaCollector',{
                                idempresa : selecionado.idempresa,
                                idcliente : selecionado.idcliente, 
                                idlicenca : selecionado.idlicencacollector
                            }).then(response=>{
                                if(response.data.status.trim() == 'OK'){
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Registro atualizado com sucesso', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'success', 
                                        timer : 1500  
                                       });
                                       getLicencasCollector();
                                }else{
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Não foi possível atualizar', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'danger', 
                                        timer : 1500  
                                       });
                                }
                               
                            }).catch(error=>{
                                Swal.fire({
                                    title :'Datacount', 
                                    text : 'Registro atualizado com sucesso', 
                                    showConfirmButton : false, 
                                    showCancelButton : false,  
                                    icon :'error', 
                                    timer : 1500  
                                   });
                            })
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Habilitar 
            </Button>
            </Tooltip>
        </Grid>
        <Grid item xs ={ 2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title ='Click aqui para remover este equipamento definitivamente'>
            <Button 
                variant = 'outlined' 
                disabled={!selecionado.idcliente || ((selecionado && selecionado.idcliente && selecionado.ativo =='f'))} 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja DESABILITAR esta Licença de Coleta?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            setSelecionado([])
                            Conexao.post('dcaController/desativarLicencaCollector',{
                                idempresa : selecionado.idempresa,
                                idcliente : selecionado.idcliente, 
                                idlicenca : selecionado.idlicencacollector
                            }).then(response=>{
                                if(response.data.status.trim() == 'OK'){
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Registro atualizado com sucesso', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'success', 
                                        timer : 1500  
                                       });
                                       getLicencasCollector();
                                       
                                }else{
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Não foi possível atualizar', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'warning', 
                                        timer : 1500  
                                       });
                                }
                               
                            }).catch(error=>{
                                Swal.fire({
                                    title :'Datacount', 
                                    text : 'Não foi possível atualizar', 
                                    showConfirmButton : false, 
                                    showCancelButton : false,  
                                    icon :'error', 
                                    timer : 1500  
                                   });
                            })
                        }
                    })
                    
                   
                }}>
                    <LockIcon /> Desabilitar
            </Button>
            </Tooltip>
        </Grid>
        

        <Grid item xs={3}>
            <ModalHabilitaMonitorToner disabled={!selecionado.idcliente} getData={()=>getLicencasCollector()} licenca={selecionado}/>
        </Grid>
    </Grid>
        <br/>
        
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-small table-sm' style ={{fontSize:'12px'}}>
            <TableHead>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>ID</TableCell>
                <TableCell align='left' style = {{width:"45%",fontSize:'12px'}}>Nome Cliente</TableCell>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Criação</TableCell>  
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Coletores DCA</TableCell>                
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Equipamentos</TableCell>
                <TableCell align='center' style = {{width:"8%",fontSize:'12px'}}>Toner</TableCell>
                {/*<TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Eventos</TableCell>*/}
                <TableCell align='center' style = {{width:"8%",fontSize:'12px'}}>Status</TableCell>
            </TableHead>

            <TableBody>
                
                {
                    licencasCollector && licencasCollector.map(function(licenca, i){
                        return <TableRow key ={i} onClick={()=>{
                    
                            if(selecionado && selecionado.idcliente == licenca.idcliente  && selecionado.chave == licenca.chave){
                                setSelecionado([])
                            }else{
                                setSelecionado(licenca)
                               
                            }
                        }} style ={{
                            backgroundColor : selecionado && selecionado.idcliente == licenca.idcliente  && selecionado.chave == licenca.chave ? "#E3F2FD" : '',
                            opacity : selecionado.chave && selecionado.chave != licenca.chave ? 0.5 : 1 
                        }}>
                            <TableCell align='center' style ={{fontSize:'12px'}}>{ i + 1}</TableCell>
                
                        <TableCell align='left' style ={{fontSize:'12px'}} >{licenca.razaosocial}</TableCell>
                        <TableCell align='center' style ={{fontSize:'12px'}}>{licenca.datacriacao}</TableCell>    
                        <TableCell align='center' style ={{fontSize:'12px'}}>
                         
                            <ModalDcas chave={licenca.chave} Conexao={Conexao} idusuario={licenca.idusuario} idempresa={licenca.idempresa} idcliente={licenca.idcliente} />
                           
                            </TableCell>                  
                            <TableCell align='center' style ={{fontSize:'12px'}}>
                                <Tooltip title='Clique para ver os equipamentos vinculados a este cliente'>
                                <Badge color='secondary' badgeContent={licenca.equipamentos}><PrintIcon style={{cursor:'pointer'}} color='primary' /></Badge>
                                </Tooltip>
                            </TableCell>    
                        <TableCell align='center' style ={{fontSize:'12px'}} >
                                {
                                    licenca.monitorartoner =='t' ? <Typography style ={{fontSize:"12px"}}>HABILITADO</Typography>:<Typography style ={{fontSize:"12px"}}>DESABILITADO</Typography>
                                }
                    
                        </TableCell>
                       {/*} <TableCell align='center' style ={{fontSize:'12px'}} >
                                <ModalHabilitarMonitoramentoEventos getData={()=>getLicencasCollector()} licenca={licenca}/>
                    {licenca.monitorareventos == 't' ? <span style ={{fontSize:"9px"}}>ATIVO</span> : <span style ={{fontSize:"9px"}}>INATIVO</span> }
                            </TableCell>*/}
                        <TableCell align='center' style ={{fontSize:'12px'}} >
                            {licenca.ativo == 't' ? <Typography style ={{fontSize:"12px"}}>ATIVO</Typography>:<Typography style ={{fontSize:"12px"}}>INATIVO</Typography>}
                            
                           </TableCell>
                    </TableRow>
                    })
                }
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <div className='fabIncluir'>
    <ModalNovaLicenca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getLicencasCollector()}/>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)