import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Checkbox, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/DeleteForever';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ModalFullDetalhes from '../../components/ModalFull2'
import DetalhesColeta from './DetalhesColeta'
import GestureIcon from '@material-ui/icons/Gesture'
import DoorOpen from '@material-ui/icons/MeetingRoom'
import LinkOn from '@material-ui/icons/Link'
import LinkOff from '@material-ui/icons/LinkOff'
import Slider from '@material-ui/core/Slider'
import AtivarIcon from '@material-ui/icons/Done'
import FilterClear from '@material-ui/icons/Block'


let temporizador = null;

const GestaoColetas = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [selecionado, setSelecionado] = React.useState([]);
    const [coletas, setColetas] = useState([])
    const [coletasFiltro, setColetasFiltro] = useState([])
    const [coleta, setColeta] = useState([])
    const [registros, setRegistros] = useState(0)
    const [colunaFiltro, setColunaFiltro] = useState('Modelo')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
  
    const refRazaoSocial = React.useRef();
    const checkboxesRef = useRef([]); // Referência para armazenar as referências dos checkboxes
    const [selectAll, setSelectAll] = useState(false); // Estado para controlar a marcação de todos os checkboxes
    const [checkdAll, setCheckedAll]= React.useState(false);
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    

    useEffect(()=>{
        getData();
        setPage(0)
    }, [])


    useEffect(()=>{
        getData();
    }, [page, rowsPerPage])


    
   


    const ativarColeta = (idEmpresa, idCliente, idColeta) =>{
        setLoading(true)
        Conexao.post('coletasController/ativarColeta', {
            idEmpresa : idEmpresa, 
            idCliente : idCliente, 
            idColeta : idColeta
        }).then(response=>{
            if( response.status == 200 && response.data.status == 'OK'){
                Swal.fire({
                    title : 'Databit Collector', 
                    text : 'Monitoramento habilitado com sucesso!', 
                    showCancelButton : false,
                    cancelButtonText : 'Cancelar', 
                    showConfirmButton :false, 
                    icon : 'success', 
                    timer : 1000
                })
               
            }else{
                Swal.fire({
                    title : 'Databit Collector', 
                    text : 'Não foi possível habilitar!', 
                    showCancelButton : false,
                    cancelButtonText : 'Cancelar', 
                    showConfirmButton :false, 
                    icon : 'warning', 
                    timer : 1000
                })
                setLoading(false)
            }
            getData();
        }).catch(error=>{
            Swal.fire({
                title : 'Databit Collector', 
                text : 'Não foi possível habilitar!', 
                showCancelButton : false,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error', 
                timer : 1000
            })
            setLoading(false)
        })
    }
    const getData = () =>{
        setLoading(true)
        Conexao.post('coletasController/getEquipamentosDesabilitados', {
            idempresa : props.match.params.chaveEmpresa, 
          
            razaosocial  : refRazaoSocial.current ?  refRazaoSocial.current.value : '',
            limit: rowsPerPage,
            offSet :  page, 
           
           
        }).then(response=>{
            console.log(response.data)
            if(response.data.status == 'OK'){
                setColetas(response.data.payload.dados)
                setColetasFiltro(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setColetas([])
                setColetasFiltro([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error =>{
            setColetas([])
            setRegistros(0)
            setColetasFiltro([])
            setLoading(false)
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }

   
 


    return <Principal atualizar = {loading} full={true}  breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Coletas Desabilitadas
            </Link>                
        </Breadcrumbs>
    }>
         <div >
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}></Grid>
                <Grid item xs = {5}>
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage='Linhas por página'
                    />
                        </Grid>
                    </Grid>
        </div>
        <br/>
        <br/>
      
        
        <Grid container spacing ={2} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'-30px'}}>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>{colunaFiltro.toString().toUpperCase()}</Typography></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                        const palavraChave = refRazaoSocial.current.value;
                        switch(colunaFiltro){
                            case 'Endereço':
                                setColetasFiltro(coletas.filter(function(equipamento) {
                                    return equipamento.enderecoip.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'DCA':
                                setColetasFiltro(coletas.filter(function(equipamento) {
                                    return equipamento.iddca.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                            case 'Cadastro':
                                setColetasFiltro(coletas.filter(function(equipamento) {
                                    return equipamento.datacadastro.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
           
                            case 'Serial':
                                setColetasFiltro(coletas.filter(function(equipamento) {
                                    return equipamento.numeroserie.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
        
                            case 'Modelo':
                                setColetasFiltro(coletas.filter(function(equipamento) {
                                    return equipamento.modelo.toLowerCase().includes(palavraChave.toLowerCase());
                                  })
                                 )
        
                            break;
                        }
                    }
                }}
            />
        </Grid>
        <Grid item xs ={ 2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{
                
                 refRazaoSocial.current.value = ''
                 getData()
                 
            }}><SearchIcon/></Button></Tooltip>
        </Grid>

        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title ='Click aqui para habilitar o monitoramento deste equipamento'>
            <Badge color='primary' badgeContent={coletasFiltro.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0)}>
            <Button 
                variant = 'outlined' 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={coletasFiltro.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0).length > 0}  onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja HABILITAR o monitoramento para este equipamento?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.post('coletasController/habilitarListaMonitoramento?idempresa',{
                                listaColetas : coletasFiltro.filter(item =>item.selecionado =='t').map((item)=>{
                                    return item.idcoleta
                                })
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    getData()
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })                           
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Reativar 
            </Button>
            </Badge>
            </Tooltip>
        </Grid>
        <Grid item xs ={ 2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title ='Click aqui para remover este equipamento definitivamente'>
                <Badge color='primary' badgeContent={coletasFiltro.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0)}>
            <Button 
                variant = 'outlined' 
                disabled={coletasFiltro.reduce((acumulado, item)=>{
                    if(item.selecionado =='t'){
                        return acumulado + 1;
                    }
                    return acumulado;
                }, 0).length > 0} 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja REMOVER definitivamente este equipamento?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.post('coletasController/desativarListaColeta?idempresa',{
                                listaColetas : coletasFiltro.filter(item =>item.selecionado =='t').map((item)=>{
                                    return item.idcoleta
                                })
                            }).then(response=>{
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação realizada com sucesso !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'success'
                                    })
                                    getData()
                                }else{
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text:'Operação não realizada', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer  : 1500, 
                                        icon : 'warning'
                                    })
                                }
                            }).catch(error=>{
                                Swal.fire({
                                    title : 'Datacount', 
                                    text:'Ocorreu um erro inesperado !', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer  : 1500, 
                                    icon : 'error'
                                })
                            })
                        }
                    })
                    
                   
                }}>
                    <LockIcon /> Remover
            </Button>
            </Badge>
            </Tooltip>
        </Grid>


        </Grid>
        <br/>
        
       
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table className='table table-bordered  table-hover table-small table-sm' >
                <TableHead>
                    <TableCell align='center' style = {{width:"3%"}}>
                        <Checkbox checked={checkdAll} color='primary' onClick={()=>{
                    const temp = coletasFiltro;
                    if(checkdAll){
                        setCheckedAll(false)
             
                        for(let i = 0; i < temp.length; i++){
                            temp[i].selecionado = 'f';
                        }
                        setColetasFiltro(temp)
                    }else{
                        setCheckedAll(true)
             
                        for(let i = 0; i < temp.length; i++){
                            temp[i].selecionado = 't';
                        }
                        setColetasFiltro(temp)
                    }
                    
                }}/>
                    </TableCell>
                    <TableCell align='center' style = {{width:"3%"}}>
                    <Typography onClick={()=>{setColunaFiltro('Endereço')}} variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Endereço'? "bold" : ''}} >Endereço</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"3%"}}>
                    <Typography onClick={()=>{setColunaFiltro('DCA')}} variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='DCA'? "bold" : ''}} >DCA</Typography>
                    </TableCell>  
                    <TableCell align='center' style = {{width:"12%"}}>
                    <Typography onClick={()=>{setColunaFiltro('Cadastro')}} variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Cadastro'? "bold" : ''}} >Cadastro</Typography>    
                    </TableCell>                    
                    <TableCell align='center' style = {{width:"12%"}}>
                        <Typography onClick={()=>{setColunaFiltro('Atualização')}} variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Atualização'? "bold" : ''}} >Atualização</Typography>
                    </TableCell>
                    <TableCell align='left' style = {{width:"20%"}}>
                        <Typography onClick={()=>{setColunaFiltro('Serial')}} variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Serial'? "bold" : ''}} >Nº Série</Typography>
                    </TableCell>
              
                    <TableCell align='left' style = {{width:"30%"}}>
                        <Typography onClick={()=>{setColunaFiltro('Modelo')}} variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Modelo'? "bold" : ''}} >Modelo</Typography>
                    </TableCell>
                    
                </TableHead>
                
                
                <TableBody>
                   
                {
                    coletasFiltro && coletasFiltro.map(function(coleta, i){
                        return <TableRow key = {'ROWCOL'+i} className={coleta.ativo =='f' ? 'table-row-inativo':''} onClick={()=>{
                    
                            
                        }} >
                            <TableCell align='center' >
                                <Checkbox className='chk-select-coleta' id={'chk-select-'+i} checked={coleta.selecionado =='t'}  onChange={(e)=>{
                                   const temp = [...coletasFiltro]; // Cria uma cópia do array coletasFiltro
                                   temp[i] = { ...temp[i], selecionado: temp[i].selecionado === 'f' ? 't' : 'f' }; // Atualiza a propriedade selecionado no objeto correspondente
                                   
                                   setColetasFiltro(temp); // Atualiza o estado coletasFiltro com a cópia do array atualizado
                                   
                                   
                                }}/>
                            </TableCell>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            <Typography variant='caption' style ={{fontSize:"11px"}}>{coleta.enderecoip}</Typography>
                        </TableCell>
                        <TableCell align='center'style ={{paddingTop:"5px", paddingBottom:"5px"}} >
                            <Typography variant='caption' style ={{fontSize:"11px"}}>{coleta.iddca}</Typography>
                        </TableCell>                    
                        <TableCell align='center'style ={{paddingTop:"5px", paddingBottom:"5px"}} >
                                <Typography color='primary' variant='caption' style ={{fontSize:"11px"}}>{coleta.datacadastro+' '+coleta.horacadastro.substr(0, 8)}</Typography>
                                
                        </TableCell>                    
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            
                            <Typography color='primary' variant='caption' style ={{fontSize:"11px"}}>{coleta.datacoleta+' '+coleta.horacoleta.substr(0, 8)}</Typography>
                        
                        </TableCell>
                        <TableCell align='left' style ={{paddingTop:"5px", paddingBottom:"5px", }}>
 
                            <Typography color='primary' className='texto-limitado' variant='caption' style={{fontSize:'11px'}} >
                            <strong>{coleta.numeroserie}</strong>
                            </Typography>
                        
                        </TableCell>
                        <TableCell align='left' style ={{paddingTop:"5px", paddingBottom:"5px", }}>
                                                   
                            <Typography color='primary' className='texto-limitado' variant='caption' style={{fontSize:'11px'}} >
                            {coleta.modelo}
                            </Typography>

                        </TableCell>
                        
                        
   
                    </TableRow>
                    })
                    
                    }
                    {
                        coletas.length == 0 && <TableRow>
                        <TableCell colSpan={8} align='left'>
                            <Alert severity='info'>{loading ?'Aguarde... buscando informações.' : '* Não existem contadores para o filtro especificado.'}</Alert>
                        </TableCell>
                       
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
      <ModalDetalhesLeitura idleitura = {idLeitura} closeModal = {()=>setIdLeitura(-1)}/>

      <ModalDetalhesLeitura idleitura = {idLeitura} closeModal = {()=>setIdLeitura(-1)}/>
      <ModalFullDetalhes 
        showModal={coleta && coleta.idcoleta} 
        marginTop
        setOpen = {()=>setColeta([])}
        titulo ={coleta.idcoleta && coleta.numeroserie+' - '+coleta.modelo}>
            <DetalhesColeta state = {coleta} showModal={coleta && coleta.idcoleta} />
        </ModalFullDetalhes>


    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoColetas)