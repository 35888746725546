import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


let temporizador = null;
const TrocaSuprimentos = (props)=>{
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [ trocas, setTrocas] = useState([])
   


    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refStatus = React.createRef();
    const refDataInicial = React.createRef();
    const refDataFinal = React.createRef();
    const refPlaca = React.createRef();
    const refNumeroSerie = React.createRef();
    const refModelo = React.createRef();
    const refMarca = React.createRef();
    
    const refRazaoSocial = React.createRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getData();
    }, [])

  


    useEffect(()=>{
     
    }, [trocas])

    

    const clearForm = () =>{

        refDataInicial.current.value  ='';
        refDataFinal.current.value  ='';
        refPlaca.current.value  ='';
        refNumeroSerie.current.value  ='';
        refModelo.current.value  ='';
        refMarca.current.value  ='';
        refRazaoSocial.current.value  ='';
    }
   

    const getData = () =>{
        Conexao.post('coletasController/getTrocasToner', {
            idempresa : props.match.params.chaveEmpresa, 
            idusuario : props.match.params.idempresa, 
            lidas : refStatus.current ?  refStatus.current.value : '', 
            datainicial : refDataInicial.current ?  refDataInicial.current.value : '', 
            datafinal : refDataFinal.current ?  refDataFinal.current.value : '', 
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            razaosocial  : refRazaoSocial.current ?  refRazaoSocial.current.value : '' 
        }).then(response=>{
            
            if(response.data.status == 'OK' ){
                
                setTrocas(response.data.payload)
            }else{
                setTrocas([])
            }
        }).catch(error =>{
           
            setTrocas([])
           
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }

   
   const renderStatus = (status, ip) =>{
    switch(status){
    case 1:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ON LINE</Typography></div>
            
        case 3:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        default:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }

   const renderNivelToner = (nivel, rotulo, descricao, contador) =>{
       if(!nivel){
           nivel = 0;
       }
        switch(rotulo){
            case 'CIANO': return <DisplayToner  
                rotulo ='ciano'
                noTop={true}                 
                width={"48px"} 
                height={"48px"} 
                color = {"#0d47a1"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'MAGENTA': return <DisplayToner  
                rotulo ='magenta' 
                noTop={true}                 
                width={"48px"} 
                height={"48px"} 
                color = {"#c62828"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'AMARELO': return <DisplayToner  
                rotulo ='amarelo' 
                noTop={true}                 
                width={"48px"} 
                height={"48px"} 
                color = {"#ffb300"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'PRETO': return <DisplayToner  
                rotulo ='preto'
                noTop={true}                  
                width={"48px"} 
                height={"48px"} 
                color= {"#000"} 
                percentual={nivel} 
                lineSize={8} 
                contador={contador}
                descricao={descricao} />
        }
        
    
   }

   const renderContadores = (mono, color, total) =>{
    /*if(!dados.contadortotalgeralresolucao){
        
        return <React.Fragment>
                    <Typography variant='caption' color='primary'><strong>{dados.contadorcolortotalresolucao  } </strong></Typography><br/>
                    <Typography variant='caption' style ={{fontSize:"9px"}}>[{dados.contadormonototalresolucao+' - '+dados.contadormonototalresolucao}]</Typography>
        </React.Fragment>
    }else{
        return <React.Fragment>
            <Typography variant='caption' color='primary'><strong>{dados.contadortotalgeralresolucao  } </strong></Typography><br/>
            <Typography variant='caption' style ={{fontSize:"9px"}}>[{dados.contadormonototalresolucao+' - '+dados.contadorcolortotalresolucao}]</Typography>
        </React.Fragment>
    }*/
   }



    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Detecções e Trocas de Suprimentos
            </Link>                
        </Breadcrumbs>
    }>
       <Grid container spacing = {2}>
            <Grid item xs = {7}></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage='Linhas por página'
                />
            </Grid>
        </Grid>
        
        <Grid container spacing = {2} style ={{marginTop :'30px'}}>
            <Grid item xs = {2}>
                <TextField
                    label ='Status'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef={refStatus}
                    size ='small'
                    >
                    <option value ='f'>NÃO LIDAS</option>
                    <option value = 't'>LIDAS</option>
                    
                    </TextField>
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataInicial}             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataFinal}             
                   />
            </Grid>

            <Grid item xs = {4}>
                <TextField
                    label ='Número Série'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refNumeroSerie}                   
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getData()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>clearForm()}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refMarca}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refModelo}                   
                   />
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refRazaoSocial}
                    size ='small'
                    />                   
            </Grid>
            

        </Grid>
        <br/>
        
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table  className='table table-bordered table-striped table-hover table-sm'>
                <TableHead>
                <TableCell align='center' style = {{width:"15%"}}>Data</TableCell>
                    <TableCell align='left' style = {{width:"15%"}}>Serial</TableCell>  
                    <TableCell align='left' style = {{width:"15%"}}>Modelo</TableCell>  
                    <TableCell align='center' style = {{width:"10%"}}>Nível</TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>Contador</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Trocado</TableCell>
                                                 
                    <TableCell align='center' style = {{width:"8%"}}>Ocultar</TableCell>

                </TableHead>
                <TableBody>
               {
                   trocas && trocas.map(function(troca, i){
                       return <TableRow key = {i}>
                        <TableCell align='center'><Typography variant='caption'>{troca.datalog.substr(0,10)+' '+troca.horalog.substr(0,8)}</Typography></TableCell>
                           <TableCell align='left' >
                                <Typography variant='caption'>{troca.numeroserie}</Typography>
                            </TableCell>
                            <TableCell align='left' >
                                <Typography variant='caption'>{troca.modelo}</Typography>
                            </TableCell>
                            <TableCell>
                                {renderNivelToner(troca.nivel, troca.cor, troca.serialtoneranterior ? troca.serialtoneranterior :'n/d', '')}
                            </TableCell>
                            <TableCell>
                            
                            </TableCell>
                            <TableCell>
                                {renderNivelToner(troca.nivelnovo, troca.cor, troca.serialtonernovo ? troca.serialtonernovo : 'n/d', '')}
                            </TableCell>
                            
                            <TableCell align='center'>
                                {troca.lida != 't' && <Button color='primary' variant='outlined' onClick={()=>{
                                    Swal.fire({
                                        title : 'Printerconnect', 
                                        text :'Deseja marcar como lida ?', 
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Confirmar', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Cancelar', 
                                        icon : 'question'
                                    }).then(resposta=>{
                                        if(resposta.isConfirmed){
                                            Conexao.get('coletasController/hideLogTrocaToner?idempresa='+props.match.params.chaveEmpresa+'&idlog='+troca.idlogtroca+'&acao='+(troca.lida =='t' ? 'f' : 't'
                                            ))
                                                .then(response=>{
                                                    if(response.data.status =='OK'){
                                                        getData();
                                                        Swal.fire({
                                                            title : 'Printerconnect', 
                                                            text :'Registro atualizado com sucesso ', 
                                                            showConfirmButton : false, 
                                                            confirmButtonText : 'Confirmar', 
                                                            showCancelButton : false, 
                                                            cancelButtonText : 'Cancelar', 
                                                            icon : 'success', 
                                                            timer :1500
                                                        })
                                                    }else{
                                                        Swal.fire({
                                                            title : 'Printerconnect', 
                                                            text :'Não foi possível atualizar', 
                                                            showConfirmButton : false, 
                                                            confirmButtonText : 'Confirmar', 
                                                            showCancelButton : false, 
                                                            cancelButtonText : 'Cancelar', 
                                                            icon : 'warning', 
                                                            timer :1500
                                                        })
                                                    }
                                                })
                                                .catch(error=>{
                                                    Swal.fire({
                                                        title : 'Printerconnect', 
                                                        text :'Não foi possível atualizar', 
                                                        showConfirmButton : false, 
                                                        confirmButtonText : 'Confirmar', 
                                                        showCancelButton : false, 
                                                        cancelButtonText : 'Cancelar', 
                                                        icon : 'error', 
                                                        timer :1500
                                                    })
                                                });
                                        }
                                    })
                                }}>
                                    <VisibilityOffIcon  />
                                </Button>}
                                
                            </TableCell>
                       </TableRow>
                   })
               }
                </TableBody>
                
                
                <TableBody>
                   
                
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
     
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(TrocaSuprimentos)