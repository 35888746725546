import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import AtolamentIcon from '@material-ui/icons/Gesture'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import GraficoTonerAmarelo from './GraficoLinhasAmarelo'
import GraficoTonerCiano from './GraficoLinhasCiano'
import GraficoTonerMagenta from './GraficoLinhasMagenta'
import GraficoTonerPreto from './GraficoLinhasPreto'
import {Alert, AlertTitle} from '@material-ui/lab/';
import ModalHabilitaMonitorIndividual from './ModalMonitoramentoIndividual'
import GraficoProducao from './Componentes/GraficoContadoresEquipamento'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import LanguageIcon from '@material-ui/icons/Language';
import ListaAlertas from './ListaAlertas'
import ModalEvolucaoToner from './ModalEvolucaoToner';

const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const [coleta, setColeta ]= React.useState([])
    const [dataGraficoDetalhesContadores, setDataGraficoDetalhesContadores]= React.useState([])
   
   

    React.useEffect(()=>{
     
        setColeta(props.state)
        getDetalhes(props.state.idcoleta)
    }, [])
        
    

    const getDetalhes = (idcoleta) =>{
        Conexao.get('coletasController/getDetalhes?idcoleta='+idcoleta ).then(response=>{
            if(response.data.status == 'OK'){
                setColeta(response.data.payload)
                getDataGraficoContadoresDetalhesEquipamento(response.data.payload.idempresa, response.data.payload.idcliente, 0, response.data.payload.numeroserie)
            }else{
                setColeta([])
            }
        }).catch(error =>{
            setColeta([])
            Swal.fire({
                title : 'Datacount', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }


    const getDataGraficoContadoresDetalhesEquipamento = (idempresa, idcliente, intervalo, numeroserie) =>{
            Conexao.get('coletasController/getDataGraficoContadoresDetalhesEquipamento?idempresa='+idempresa+'&idcliente='+idcliente+'&intervalo='+intervalo+'&numeroserie='+numeroserie).then(response=>{
                if(response.data.status == 'OK'){
                    setDataGraficoDetalhesContadores(response.data.payload)
                }else{
                    setDataGraficoDetalhesContadores([])
                }
            }).catch(error =>{
                setColeta([])
                Swal.fire({
                    title : 'Datacount', 
                    text : 'Sem conexão com servidor remoto !', 
                    showCancelButton : true,
                    cancelButtonText : 'Cancelar', 
                    showConfirmButton :false, 
                    icon : 'error'
                })
            });
    }

    const renderStatus = (status, dias, flex =false) =>{
        if(dias > 0){
        return <div style ={{color:"#d50000", display : flex ? 'flex' : ''}}><BlockIcon /></div>
        }
        switch(status){
        case 1:return <div style ={{display : flex ? 'flex' : ''}}><HelpIcon style ={{color:"#d50000"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Desconhecido...</Typography></div>
                
            case 2:return <div style ={{display : flex ? 'flex' : ''}}><PowerSettingsNewIcon style ={{color:"#004d40"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>On Line...</Typography></div>
                
            case 3:return <div style ={{display : flex ? 'flex' : ''}}><SettingsPowerIcon style ={{color:"#ef6c00"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Energy Saving...</Typography></div>
                
            case 4:return <div style ={{display : flex ? 'flex' : ''}}><PrintIcon style ={{color:"#01579b"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Imprimendo...</Typography></div>
    
            case 5:return <div style ={{display : flex ? 'flex' : ''}}><ReportProblemIcon style ={{color:"#d50000"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Ocorrência...</Typography></div>

            case 7:return <div style ={{display : flex ? 'flex' : ''}}><AtolamentIcon style ={{color:"#F00"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Atolamento de Papel...</Typography></div>

            case 8:return <div style ={{display : flex ? 'flex' : ''}}><AtolamentIcon style ={{color:"#F00"}}/> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Atolamento de Papel...</Typography></div>
    
            default:return <div style ={{display : flex ? 'flex' : ''}}><HelpIcon /> <Typography style = {{fontSize:"11px", marginTop : '3px', marginLeft : '5px'}}>Desconhecido...</Typography></div>
        }
            
       }


       const renderTipoAlerta =(erro)=>{
           
            switch(erro.prtalertseveritylevel){
                case -1:
                    return <Alert severity="default" className='no-background' >
                                <AlertTitle>{erro.horaalerta ? erro.dataalerta+' '+erro.horaalerta.substr(0,8) : ''}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 1:
                    return <Alert severity="info" className='no-background' >
                                <AlertTitle>{erro.horaalerta ? erro.dataalerta+' '+erro.horaalerta.substr(0,8) : ''}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 2:
                    return <Alert severity="info" className='no-background' >
                                <AlertTitle>{erro.horaalerta ? erro.dataalerta+' '+erro.horaalerta.substr(0,8) : ''}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 3:
                    return <Alert severity="error" className='no-background' >
                                <AlertTitle>{erro.horaalerta ? erro.dataalerta+' '+erro.horaalerta.substr(0,8) : ''}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>
                 case 4:
                    return <Alert severity="warning" className='no-background' >
                                <AlertTitle>{erro.horaalerta ? erro.dataalerta+' '+erro.horaalerta.substr(0,8) : ''}</AlertTitle>
                                <strong>{erro.descricao} </strong>{erro.prtalertlocation}
                            </Alert>

            }

        
       }

    const decodeSysLocation = (syslocation) =>{
        try{
            return atob(syslocation);
        }catch(e){
            return syslocation;
        }
    }


    return <div atualizar = {loading}>
      
        {coleta.idcoleta ?
        <div className='div-form-container' style = {{ paddingLeft:"20px", paddingRight:"20px", minHeight:"50vh"}}>
        
        {
            coleta && coleta.ativo == 'f' && <Alert severity='error'>* A coleta para este equipamento esta desativada !!</Alert>
        }

        <Grid container spacing={1}>
            <Grid item xs = {8}>
                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Modelo</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{coleta.modelo}</strong></Typography>                      
                    </Grid>
                </Grid>
                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Marca</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{coleta.marca}</strong></Typography>                      
                    </Grid>
                </Grid>
                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Número de Série</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{coleta.numeroserie}</strong></Typography>                      
                    </Grid>
                </Grid>

                

                

                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Cliente</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.cliente ? coleta.cliente.razaosocial : coleta.razaosocial}</strong></Typography>                      
                    </Grid>
                </Grid>

                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Endereço IP</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.enderecoip}</strong></Typography>                      
                    </Grid>
                </Grid>                

                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Macaddress</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.macaddress ? coleta.macaddress : '* não disponível'}</strong></Typography>                      
                    </Grid>
                </Grid>

                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>1º Coleta</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.datacadastro ? coleta.datacadastro+' '+coleta.horacadastro.substr(0,8) : '* não disponível'}</strong></Typography>                      
                    </Grid>
                </Grid>


                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>SysUptime</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.sysuptime ? coleta.sysuptime : '* não disponível'}</strong></Typography>                      
                    </Grid>
                </Grid>
                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Setor (SysLocation)</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.syslocation ? decodeSysLocation(coleta.syslocation) : '* não disponível'}</strong></Typography>                      
                    </Grid>
                </Grid>


                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Memória</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.memoria}</strong></Typography>                      
                    </Grid>
                    
                </Grid>

                { coleta.harddisc != null && <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Disco Rígido</Typography>                       
                    </Grid>
                    <Grid item xs = {4}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.harddisc}</strong></Typography>                      
                    </Grid>
                    <Grid item xs = {2}>
                        <Typography variant='subtitle2' color='primary'><strong>Serial: </strong>{ coleta.harddiscserial}</Typography>                      
                    </Grid>
                    <Grid item xs = {2}>
                        <Typography variant='subtitle2' color='primary'><strong>Size: </strong>{ coleta.harddiscsize}</Typography>                      
                    </Grid>
                    <Grid item xs = {2}>
                        <Typography variant='subtitle2' color='primary'><strong>Free: </strong>{ coleta.harddiscfree ? coleta.harddiscfree : 'n/d'}</Typography>                      
                    </Grid>
                    
                </Grid>}


                { coleta.documentserver != null && <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Doc Server</Typography>                       
                    </Grid>
                    <Grid item xs = {5}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.documentserver ? coleta.documentserver : '* não disponível'}</strong></Typography>                      
                    </Grid>
                    <Grid item xs = {5}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.documentserverfree ? coleta.documentserverfree : '* não disponível'}</strong></Typography>                      
                    </Grid>
                </Grid>}


                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Status comunicação</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.dias == 0 ? coleta.datacoleta+' '+coleta.horacoleta.substr(0,8) : <span style ={{color: '#f00'}}>{'* sem comunicação há '+coleta.dias+' dia(s)'}</span>}</strong></Typography>                      
                    </Grid>
                </Grid>

               


                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Mensagem painel</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'><strong>{ coleta.mensagempainel ? coleta.mensagempainel : '* não disponível'}</strong></Typography>                      
                    </Grid>
                </Grid>

                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Status Sistema</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statussistema, coleta.dias, true)}</Typography>                      
                    </Grid>
                </Grid>

                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Status Copia</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statuscopiadora, coleta.dias, true)}</Typography>                      
                    </Grid>
                </Grid>
                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Status Impressora</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statusimpressora, coleta.dias, true)}</Typography>                      
                    </Grid>
                </Grid>
                <Grid container spacing ={1}>
                    <Grid item xs = {2} style ={{textAlign:'right'}}>
                        <Typography variant='subtitle2' color='primary'>Status Scanner</Typography>                       
                    </Grid>
                    <Grid item xs = {10}>
                        <Typography variant='subtitle2' color='primary'>{renderStatus(coleta.statusscanner, coleta.dias, true)}</Typography>                      
                    </Grid>
                </Grid>

                <div>
                {coleta.erros && <br/>}
        {
            coleta.erros && coleta.erros.map(function(erro, i){
                return renderTipoAlerta(erro)
            })
        }
                </div>
            </Grid>
            <Grid item xs = {4}>
                <div style ={{width: '100%', border: 'solid 1px #ccc', borderRadius : '5px'}}>
                    <div style ={{backgroundColor:'#c6c6c6', textAlign:'center', padding :'5px'}}><Typography variant='caption' style ={{color:'#000'}}>MEDIDORES DISPONÍVEIS</Typography></div>
                    <div style ={{padding : '5px'}}>
                        <Table className='table table-small table-sm table-strinped table-hover'>
                            <TableHead>
                                <TableCell align='left' style={{width : '60%', fontSize : '10px'}}>Tipo</TableCell>
                                <TableCell align='right' style={{width : '40%', fontSize : '10px'}}>Valor</TableCell>
                                
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total Geral</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadortotalgeral}</strong></TableCell>
                                    
                                </TableRow>

                               {coleta.contadorrelatorios != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total Relatórios</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadorrelatorios}</strong></TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorprinttotal != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total Impressões</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadorprinttotal}</strong></TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcopiatotal != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total Copias</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadorcopiatotal}</strong></TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorfaxtotal != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total Fax</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadorfaxtotal}</strong></TableCell>
                                    
                                </TableRow>}

                                {coleta.contadormonoa4 !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>A4 PB</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonoa4}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadormonoa3 !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>A3 PB</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonoa3}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadormonocopia !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Cópia PB</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonocopia}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadormonoprinter !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Printer PB</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonoprinter}</TableCell>
                                    
                                </TableRow>}

                            

                                {coleta.contadormonofax !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Fax PB</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonofax}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadormonooutros !=null  && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Outros PB</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonooutros}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadormonototal !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total PB</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadormonototal}</strong></TableCell>
                                    
                                </TableRow>}

                                
                                {coleta.contadorcolora4 !=null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>A4 Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcolora4}</TableCell>
                                    
                                </TableRow>}


                               {coleta.contadorcolora3 !=null &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>A3 Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcolora3}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcolorcopia !=null &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Cópia Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcolorcopia}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcolorprinter !=null &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Printer Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcolorprinter}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcolorfax !=null &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Fax Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcolorfax}</TableCell>
                                    
                                </TableRow>}

                                

                                {coleta.contadorcoloroutros  != null &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Outros Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcoloroutros}</TableCell>
                                    
                                </TableRow>}


                                {/*
                                {coleta.contadorcopiacorunica &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Cópia Cor Única</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcopiacorunica}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcopiacorintegral &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Cópia Cor Integral</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcopiacorintegral}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcopiaduascores &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Cópia Duas Cores</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcopiaduascores}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorprintercorintegral &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Printer Cor Integral</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorprintercorintegral}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorprintercopiacorunica &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Printer Cor Única</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorprintercopiacorunica}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorprintercopiaduascores &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Printer Duas Cores</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorprintercopiaduascores}</TableCell>
                                    
                                </TableRow>}

                                {coleta.a3dlt &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Contador A3/DLT</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.a3dlt}</TableCell>
                                    
                                </TableRow>}

                                {coleta.duplex &&  <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Duplex</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.duplex}</TableCell>
                                    
                                </TableRow>}
                                */}

                                {coleta.contadorcolortotal != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Total Color</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadorcolortotal}</strong></TableCell>
                                    
                                </TableRow>}


                                {coleta.a3dlt != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>A3/Duplo Carta</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.a3dlt ? coleta.a3dlt : 'n/d' }</strong></TableCell>
                                    
                                </TableRow>}


                                {coleta.duplex != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Duplex</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.duplex ? coleta.duplex : 'n/d' }</strong></TableCell>
                                    
                                </TableRow>}



                               <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}><strong>Scanner Total</strong></TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}><strong>{coleta.contadortotalscanner ? coleta.contadortotalscanner : (coleta.contadormonoscanner + coleta.contadorcolorscanner) }</strong></TableCell>
                                    
                                </TableRow>

                                {coleta.contadormonoscanner != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Scanner Mono</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadormonoscanner}</TableCell>
                                    
                                </TableRow>}

                                {coleta.contadorcolorscanner != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Scanner Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.contadorcolorscanner}</TableCell>
                                    
                                </TableRow>}


                                {coleta.ciclosmecanismo != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Ciclos do Mecanismo</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.ciclosmecanismo}</TableCell>
                                    
                                </TableRow>}

                                {coleta.ciclosmecanismocolor != null && <TableRow>
                                    <TableCell align='left' style={{fontSize : '10px'}}>Ciclos do Mecanismo Color</TableCell>
                                    <TableCell align='right' style={{fontSize : '10px'}}>{coleta.ciclosmecanismocolor}</TableCell>
                                    
                                </TableRow>}

                            
                            </TableBody>

                        </Table>
                       
                    </div>
                    
                </div>
                <div style={{padding : '4px'}}>
                    <Grid container spacing={1} style ={{marginTop : '30px'}}>
                        <div style ={{backgroundColor:'#c6c6c6', textAlign:'center', padding :'2px', borderRadius : '5px', width:'100%'}}><Typography variant='caption' style ={{color:'#000'}}>INFORMAÇÕES DE SUPRIMENTOS</Typography></div>
                    </Grid>
                    <Grid container spacing={1} style={{marginTop : '10px'}}>
                        <Grid item xs = {3}>  
                            <ModalEvolucaoToner nivel={
                                    <DisplayToner  
                                    disabled={!coleta.nivelciano} 
                                    percentual = {coleta.nivelciano ? coleta.nivelciano : 0} 
                                    rotulo = 'ciano' color='#283593'   descricao={coleta.numeroserieciano} contador = {coleta.contadorciano}></DisplayToner>
                            } >
                                    <GraficoTonerCiano conexao ={Conexao} serial={coleta.numeroserie} />

                                    {coleta.numeroserie && coleta.nivelciano == 0  && <Alert severity="error">Nível de toner Ciano está em zero !</Alert>}
                                    {coleta.numeroserie && coleta.nivelciano > 0 && coleta.nivelciano <= 25 && <Alert severity="warning">Equipamento sem Toner Ciano ! Verifique.</Alert>}              
                                    

                                    {
                                    coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertaciano == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>CIANO</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelciano}%.</Alert>
                                    
                                    }
                                    {
                                        coleta && coleta.alertaciano == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>CIANO</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertaciano}%.</Alert>
                                    }
                                    <br/>
                                    
                                
                            </ModalEvolucaoToner>
                            
                        </Grid>
                        <Grid item xs = {3}>   
                            <ModalEvolucaoToner
                                nivel={<DisplayToner  disabled={!coleta.nivelmagenta} percentual = {coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo = 'magenta' color='#ff1744' descricao={coleta.numeroseriemagenta} contador = {coleta.contadormagenta}></DisplayToner>             } >
                                <GraficoTonerMagenta conexao ={Conexao} serial={coleta.numeroserie}/>
                                {coleta.numeroserie && coleta.nivelmagenta == 0 && <Alert severity="error">Equipamento sem Toner Magenta ! Verifique.</Alert>}
                                {coleta.numeroserie && coleta.nivelmagenta > 0 && coleta.nivelmagenta <= 25 && <Alert severity="warning">Nível de toner Magenta está baixo !</Alert>}
                                {
                                coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertamagenta == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>MAGENTA</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelmagenta}%.</Alert>
                                
                                }
                                {
                                    coleta && coleta.alertamagenta == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>MAGENTA</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertamagenta}%.</Alert>
                                }
                                <br/>
                            </ModalEvolucaoToner>
                            
                            
                        </Grid>
                        <Grid item xs = {3}>  
                            <ModalEvolucaoToner 
                                nivel={<DisplayToner  disabled={!coleta.nivelamarelo} percentual = {coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo = 'amarelo' color='#ff8f00' descricao={coleta.numeroserieamarelo} contador = {coleta.contadoramarelo}></DisplayToner>}>
                                    <GraficoTonerAmarelo conexao ={Conexao} serial={coleta.numeroserie}/>
                                    {coleta.numeroserie && coleta.nivelamarelo == 0 && <Alert severity="error">Equipamento sem Toner Amarelo ! Verifique.</Alert>}
                                    {coleta.numeroserie && coleta.nivelamarelo > 0 && coleta.nivelamarelo <= 25 && <Alert severity="warning">Nível de toner Amarelo está baixo !</Alert>}
                                    {
                                    coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertaamarelo == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>AMARELO</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelamarelo}%.</Alert>
                                    
                                    }
                                    {
                                        coleta && coleta.alertaamarelo == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>AMARELO</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertaamarelo}%.</Alert>
                                    }
                                </ModalEvolucaoToner>                                  
                        </Grid>
                        <Grid item xs = {3}>   
                            <ModalEvolucaoToner 
                                nivel={<DisplayToner  disabled={!coleta.nivelpreto} percentual = {coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' color='#000' descricao={coleta.numeroseriepreto} contador = {coleta.contadorpreto}></DisplayToner>}>
                                <GraficoTonerPreto conexao ={Conexao} serial={coleta.numeroserie}/>
                                {coleta.numeroserie && coleta.nivelpreto == 0 && <Alert severity="error" >Equipamento sem Toner Preto ! Verifique.</Alert>}
                                {coleta.numeroserie && coleta.nivelpreto > 0 && coleta.nivelpreto <= 25 && <Alert severity="warning" >Nível de toner Preto está baixo !</Alert>}
                                {
                                coleta.cliente && coleta.cliente.monitorartoner == 't' && coleta.alertapreto == 'f' && <Alert severity="info" className='alert-default' icon={<LanguageIcon className='iconAlert-g'/>}>O Suprimento <strong>PRETO</strong> está sendo monitorado de forma <strong>GLOBAL</strong>, desta forma a configuração de níveis são as mesmas para todos os equipamentos : {coleta.cliente.nivelpreto}%.</Alert>
                                
                                }
                                {
                                    coleta && coleta.alertapreto == 't' && <Alert severity="info" className='alert-default' icon={<NetworkCheckIcon className='iconAlert-g'/>}>O Suprimento <strong>PRETO</strong> está sendo monitorado individualmente, alertas são gerados quando o Nível atingir : {coleta.nivelalertapreto}%.</Alert>
                                }
                            </ModalEvolucaoToner>
                            
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    
        <Grid container  spacing={1}>
            <Grid item xs = {12}>
            {coleta.cliente && coleta.cliente.monitorartoner =='t'  && <Alert severity="info" icon={<LanguageIcon className='iconAlert-g'/>}>Este cliente está habilitado para monitoramento <strong>global</strong> dos níveis de toner. Esta opção é uma ação com menor relevância ao monitoramento individual do equipamento, caso seja habilitado no equipamento ela terá precedência sobre a configuração global.</Alert>}
            </Grid>
           
        </Grid>
        {(coleta.ethernet != null || coleta.wifimode != null) && <div style ={{width: '100%', border: 'solid 1px #ccc', borderRadius : '5px', marginTop : '10px'}}>
            <div style ={{backgroundColor:'#c6c6c6', textAlign:'center', padding :'2px'}}><Typography variant='caption' style ={{color:'#000'}}>Rede e Conexão</Typography></div>
            <div style ={{padding : '5px', minHeight : '50px'}}> 
                <Table className='table table-small table-sm table-strinped'>
                    <TableHead>
                        <TableCell align='left' style={{width : '20%', fontSize : '10px'}}>Tipo</TableCell>
                        <TableCell align='center' style={{width : '30%', fontSize : '10px'}}>SSID</TableCell>
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Status</TableCell>
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Macaddress</TableCell>
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Security</TableCell>
                      
                    </TableHead>
                    <TableBody>
                    {coleta.ethernet != null && <TableRow>
                            <TableCell align='center' style={{ fontSize : '10px'}}>{coleta.ethernet ? coleta.ethernet : 'Ethernet'}</TableCell>                            
                            <TableCell align='center' style={{ fontSize : '10px'}}>n/d</TableCell>
                            <TableCell align='center' style={{ fontSize : '10px'}}>n/d</TableCell>
                            <TableCell align='center' style={{ fontSize : '10px'}}>{coleta.macaddress}</TableCell>
                            <TableCell align='center' style={{ fontSize : '10px'}}>n/d</TableCell>
                          
                        </TableRow>}

                    {coleta.wifimode != null && <TableRow>
                            <TableCell align='left' style={{ fontSize : '10px'}}>{coleta.wifimode}</TableCell>
                            <TableCell align='center' style={{ fontSize : '10px'}}>{coleta.ssid}</TableCell>
                            <TableCell align='center' style={{ fontSize : '10px'}}>{coleta.wifistatus}</TableCell>
                            <TableCell align='center' style={{ fontSize : '10px'}}>{coleta.macwifi}</TableCell> 
                            <TableCell align='center' style={{ fontSize : '10px'}}>{coleta.wifisecurity}</TableCell>
                       
                        </TableRow>}

                    
                   
                    </TableBody>
                </Table>
            </div>   

        </div>}


        <div style ={{width: '100%', border: 'solid 1px #ccc', borderRadius : '5px', marginTop : '10px'}}>
            <div style ={{backgroundColor:'#c6c6c6', textAlign:'center', padding :'2px'}}><Typography variant='caption' style ={{color:'#000'}}>Versões de Firmware</Typography></div>
            <div style ={{padding : '5px', minHeight : '50px'}}> 
                <Table className='table table-small table-sm table-strinped'>
                    <TableHead>
                        <TableCell align='left' style={{width : '40%', fontSize : '10px'}}>Descrição do Firmware</TableCell>
                        <TableCell align='left' style={{width : '60%', fontSize : '10px'}}>Versão</TableCell>
                    </TableHead>
                    <TableBody>
                    {coleta.versaofirmware != null && <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Principal</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.versaofirmware}</TableCell>
                        </TableRow>}

                    {coleta.subfirmware != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>SubFirmware</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.subfirmware}</TableCell>
                        </TableRow>}

                    {coleta.versaoxoa != null &&<TableRow>
                        <TableCell align='left' style={{fontSize : '10px'}}>Versão XOA</TableCell>
                        <TableCell align='left' style={{fontSize : '10px'}}>{coleta.versaoxoa}</TableCell>
                    </TableRow>}
                    
                    {coleta.versaodofirmwareengine != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Engine</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.versaodofirmwareengine}</TableCell>
                        </TableRow>}
                    {coleta.versaodofirmwareui != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Firmware UI</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.versaodofirmwareui}</TableCell>
                        </TableRow>}
                        {coleta.versaodoscanner != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Firmware Scanner</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.versaodoscanner}</TableCell>
                        </TableRow>}
                    

                    {coleta.firmwarerede!= null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwareRede</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarerede}</TableCell>
                        </TableRow>}

                    {coleta.firmwarenib != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwareNIB</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarenib}</TableCell>
                        </TableRow>}

                    {coleta.firmwareweb != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwareWeb</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwareweb}</TableCell>
                        </TableRow>}

                    {coleta.firmwarelanguage != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwareLanguage</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarelanguage}</TableCell>
                        </TableRow>}

                    {coleta.firmwarepjl != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwarePJL</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarepjl}</TableCell>
                        </TableRow>}

                    {coleta.firmwarerpcs != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwareRPCS</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarerpcs}</TableCell>
                        </TableRow>}

                    {coleta.firmwarepcl5e != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwarePCL5e</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarepcl5e}</TableCell>
                        </TableRow>}

                    {coleta.firmwarepclxl != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwarePCLXL</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarepclxl}</TableCell>
                        </TableRow>}

                    {coleta.firmwareps3 != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwarePS3</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwareps3}</TableCell>
                        </TableRow>}

                    {coleta.firmwarepdf != null &&<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>firmwarePDF</TableCell>
                            <TableCell align='left' style={{fontSize : '10px'}}>{coleta.firmwarepdf}</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>   

        </div>

        
        <div style ={{width: '100%', border: 'solid 1px #ccc', borderRadius : '5px', marginTop : '10px'}}>
            <div style ={{backgroundColor:'#c6c6c6', textAlign:'center', padding :'2px'}}><Typography variant='caption' style ={{color:'#000'}}>Consumíveis e Peças</Typography></div>
            <div style ={{padding : '5px', minHeight : '100px'}}> 
                <Table className='table table-small table-sm table-strinped'>
                    <TableHead>
                        <TableCell align='left' style={{width : '20%', fontSize : '10px'}}>Tipo</TableCell>
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Contador</TableCell>
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Capacidade</TableCell>
                        <TableCell align='center' style={{width : '20%', fontSize : '10px'}}>Serial</TableCell>
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Restante</TableCell>
                        
                        <TableCell align='center' style={{width : '10%', fontSize : '10px'}}>Restante</TableCell>
                        <TableCell align='center' style={{width : '30%', fontSize : '10px'}}>Instalação</TableCell>
                    </TableHead>
                    <TableBody>
                    {(coleta.contadorcilindrociano !=null || coleta.nivelcilindrociano != null) ?<TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade/Cilindro CIANO</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorcilindrociano}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadecilindrociano}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.serialcilindrociano}</TableCell>                                                      
                            <TableCell align='center' style={{fontSize : '10px'}}>
                                {coleta.nivelcilindrociano ? <DisplayLinearToner value ={coleta.nivelcilindrociano} rotulo ='ciano'/> : ''}
                                </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.pagesremainingcian}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.datainstalacaocartuchociano}</TableCell>
                        </TableRow> : null}

                        {(coleta.contadorcilindromagenta !=null || coleta.nivelcilindromagenta != null) ?  <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade/Cilindro MANGENTA</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorcilindromagenta}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadecilindromagenta}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.serialcilindromagenta}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                                {coleta.nivelcilindromagenta ? <DisplayLinearToner value ={coleta.nivelcilindromagenta} rotulo ='magenta'/> : ''}                              
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.pagesremainingmagenta}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.datainstalacaocartuchomagenta}</TableCell>
                        </TableRow> : null} 

                        {(coleta.contadorcilindroamarelo !=null || coleta.nivelcilindroamarelo != null) ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade/Cilindro AMARELO</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorcilindroamarelo}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadecilindroamarelo}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.serialcilindroamarelo}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                                {coleta.nivelcilindroamarelo ? <DisplayLinearToner value ={coleta.nivelcilindroamarelo} rotulo ='amarelo'/> : '' }
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.pagesremainingyellow}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.datainstalacaocartuchoamarelo}</TableCell>
                        </TableRow> : null}

                        {(coleta.contadorcilindropreto !=null || coleta.nivelcilindropreto != null) && <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade/Cilindro PRETO</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorcilindropreto}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadecilindropreto}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.serialcilindropreto}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                                {coleta.nivelcilindropreto ? <DisplayLinearToner value ={coleta.nivelcilindropreto} rotulo ='preto'/>:''}
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.pagesremainingblack}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.datainstalacaocartuchopreto}</TableCell>
                        </TableRow>}

                        {(coleta.nivelfusor != null  || coleta.contadorunitfusao) ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade de Fusão</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorunitfusao}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadefusao}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                            <DisplayLinearToner value ={coleta.nivelfusor} rotulo ='generico'/></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.contadorunittransferencia != null  || coleta.capacidadeunidadetransferencia || coleta.nivelunidadetransferencia ) ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade de Transferência</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorunittransferencia}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadeunidadetransferencia}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                            <DisplayLinearToner value ={coleta.nivelunidadetransferencia} rotulo ='generico'/>
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.contadorroletegavata1 != null  || coleta.capacidaderoletegavata1 ) ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Kit Gaveta 1 </TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorroletegavata1}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidaderoletegavata1}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.contadorreversor1 != null  || coleta.capacidadereversor1 ) ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Rolete Reversor Gaveta 1</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorreversor1}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadereversor1}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}


                        {(coleta.contadorroletegavata2 != null  || coleta.capacidaderoletegavata2 ) ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Kit Gaveta 2</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorroletegavata2}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidaderoletegavata2}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.capacidaderoletebypass != null  || coleta.contadorroletebypass ) ?  <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Kit Gaveta ByPass</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorroletebypass}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidaderoletebypass}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.contadorreversorbypass != null  || coleta.capacidadereversorbypass ) ?  <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Rolete Reversor ByPass</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorreversorbypass}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidadereversorbypass}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.contadorroleteardf != null  || coleta.capacidaderoleteardf ) ?  <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Kit ARDF</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorroleteardf}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidaderoleteardf}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {(coleta.contadorroletereversoardf != null  || coleta.capacidaderoletereversoardf ) ?  <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Rolete Reversor ARDF</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorroletereversoardf}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>{coleta.capacidaderoletereversoardf}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        

                        {coleta.nivellaser != null   ? <TableRow>
                        <TableCell align='left' style={{fontSize : '10px'}}>Unidade Laser</TableCell>
                        <TableCell align='center' style={{fontSize : '10px'}}>{coleta.contadorunitlaser}</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                            <DisplayLinearToner value ={coleta.nivellaser} rotulo ='generico'/>
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>


                        </TableRow> : null}

                        {coleta.nivelreservatoriotonerusado != null  ?  <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade de toner usado</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                            <DisplayLinearToner value ={coleta.nivelreservatoriotonerusado} rotulo ='generico'/>
                            </TableCell>
                        </TableRow> : null}
                        {coleta.usageinkunit != null ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Unidade de tinta usada</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                                <DisplayLinearToner value ={coleta.usageinkunit} rotulo ='generico'/>                                
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}

                        {coleta.documentfeederkit1 != null ? <TableRow>
                            <TableCell align='left' style={{fontSize : '10px'}}>Documento Feeder Kit</TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}>
                                <DisplayLinearToner value ={coleta.documentfeederkit1} rotulo ='generico'/>                                
                            </TableCell>
                            
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                            <TableCell align='center' style={{fontSize : '10px'}}></TableCell>
                        </TableRow> : null}


                        
                    </TableBody>
                </Table>
            </div>
            </div>
           
       
       
   

    <div  style =  {{width:"100%", height:"200px"}}>
        <Typography variant='caption'color ='primary'>Evolução da produção de páginas</Typography>
        <GraficoProducao data={dataGraficoDetalhesContadores}/>
    </div>
    <br/>
        <br/> 
    </div> : <Alert severity='info'>* Não existem informações para serem exibidas</Alert>
   
    }
     
    </div>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)